import { useUrls } from '@/composables/useUrls';
import { useApiDistricts } from '~/composables/useApiDistricts';
import { useRoutes } from '~/composables/useRoutes.js';

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.server) {
    const nuxtApp = useNuxtApp();

    // If the error is already being handled, skip the validation.
    if (nuxtApp.payload.error) {
      return;
    }

    // ## COMPOSABLES
    const { getDistrictByCityNameAndCountryName } = useApiDistricts();
    const { useRouteManager } = useRoutes();
    const { useUrl } = useUrls();

    const urlParams = to?.params;
    const urlQuery = to?.query;
    const urlPath = to?.path.toLowerCase();

    // VALIDATE PROPERTY TYPE
    // If invalid property type, then default to 'hostels' property type (according to the user language);
    const propertyTypeObj = useRouteManager.validatePropertyTypeParam(urlParams.propertyType);

    // VALIDATE DISTRICT WITH CALL TO SPAPI
    const locationObj = await getDistrictByCityNameAndCountryName(
      propertyTypeObj?.key,
      urlParams.country,
      urlParams.city,
      urlParams.district,
    );

    if (locationObj?.id) {
      // DISTRICT SEEMS TO BE A VALID ONE.
      // CHECK IF REQUESTED URL MATCHES THE USER LANGUAGE FORMAT
      const districtPageUrlForCurrentLang = useUrl.getDistrictPageUrl(
        {
          propertyTypeSlug: propertyTypeObj.slug,
          urlFriendlyContinent: locationObj.urlFriendlyContinent,
          urlFriendlyCountry: locationObj.urlFriendlyCountry,
          urlFriendlyCity: locationObj.urlFriendlyCity,
          urlFriendlyDistrict: locationObj.urlFriendlyName,
        },
        urlQuery,
      );

      const districtPagePathForCurrentLang = new URL(districtPageUrlForCurrentLang).pathname.toLowerCase();

      if (districtPagePathForCurrentLang !== urlPath) {
        // REQUESTED URL DOES NOT MATCH USER LANGUAGE FORMAT
        // REDIRECT USER TO THE APPROPRIATE URL FORMAT
        navigateTo(districtPagePathForCurrentLang, {
          redirectCode: 301,
          external: false,
        });
      }
    } else {
      // DISTRICT IS NOT FOUND, THROW 404 TO CHECK THE REDIRECTION API
      const errorDescription = `### No such District '${urlParams.district}' in City '${urlParams.city}' from Country '${urlParams.country}'`;
      console.error(errorDescription);

      throw createError({
        statusCode: 404,
        statusMessage: errorDescription,
        data: useRouteManager.enrichedErrorResponse(errorDescription, urlPath),
      });
    }
  }
});
